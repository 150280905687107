import React from "react"
import HomeLayout from "layouts/HomeLayout"
import HomeContainer from "components/HomeContainer"
import HomePageHeading from "components/HomePageHeading"
import { graphql } from "gatsby"
import Heading from "components/Heading"
import ResponsiveMap from "components/ResponsiveMap"
import styled from "styled-components"
import config from "config/config"
import AudioPlayer from "components/AudioPlayer/AudioPlayer"
import { getYoutubeIdFromUrl } from "tools/getYoutubeIdFromUrl"
import ResponsiveEmbed from "components/ResponsiveEmbed"
import loadable from "@loadable/component"
import { Helmet } from "react-helmet"
const Gallery = loadable(() => import("components/Gallery/Gallery"))
const Presenter = loadable(() => import("components/Presenter"))

const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 500px;
  margin: auto;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
`

const PageLayout = props => {
  const { title, seo_description, content, seo_image } = props.pageContext
  return (
    <>
      <Helmet>
        <title>{title} | Wspólnota 120</title>
        <meta name={"og:title"} content={`${title} | Wspólnota 120`} />
        <meta name={"description"} content={seo_description} />
        <meta name={"og:description"} content={seo_description} />
        {seo_image && (
          <meta
            name={"og:image"}
            content={config.uploadPrefix + seo_image.url}
          />
        )}
      </Helmet>
      <HomeLayout>
        <HomePageHeading>{title}</HomePageHeading>
        {content.map((item, index) => (
          <HomeContainer key={index}>
            {item.__typename === "STRAPI_ComponentComponentsMap" && (
              <>
                <Heading level={2} style={{ marginBottom: "24px" }}>
                  Mapa sympatyków
                </Heading>
                <ResponsiveMap
                  src={
                    "https://www.google.com/maps/d/u/0/embed?mid=1N_o7s_0tlQDaAige9FasuKDtMFTPAj-8"
                  }
                />
              </>
            )}
            {item.__typename === "STRAPI_ComponentComponentsText" && (
              <div dangerouslySetInnerHTML={{ __html: item.text }} />
            )}
            {item.__typename === "STRAPI_ComponentComponentsImage" &&
              item.image && (
                <StyledImage
                  src={config.uploadPrefix + item.image.url}
                  alt={""}
                />
              )}
            {item.__typename === "STRAPI_ComponentComponentsAudio" &&
              item.file && (
                <AudioPlayer title={item.title} src={item.file.url} />
              )}
            {item.__typename === "STRAPI_ComponentComponentsYoutube" && (
              <ResponsiveEmbed
                src={`https://www.youtube.com/embed/${getYoutubeIdFromUrl(
                  item.youtube
                )}`}
              />
            )}
            {item.__typename === "STRAPI_ComponentComponentsPresentation" &&
              item.file && <Presenter src={item.file.url} />}
            {item.__typename === "STRAPI_ComponentComponentsGallery" && (
              <Gallery
                images={item.gallery.map(image => ({
                  src: config.uploadPrefix + image.url,
                  width: image.width,
                  height: image.height,
                }))}
              />
            )}
          </HomeContainer>
        ))}
      </HomeLayout>
    </>
  )
}

export default PageLayout
