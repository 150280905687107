import React, { FC } from "react"
import styled from "styled-components"
import HomeContainer from "components/HomeContainer"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${props => props.theme.color.primaryLight};
  height: 96px;
  margin-bottom: 64px;

  ${props => props.theme.media.sm} {
    height: 128px;
  }
`

const Heading = styled.h1`
  margin: 0;
  font-weight: normal;
  font-size: 42px;
  transform: translateY(12px);

  ${props => props.theme.media.sm} {
    font-size: 64px;
    transform: translateY(18px);
  }
`

const HomePageHeading: FC = ({ children }) => {
  return (
    <Wrapper>
      <HomeContainer noMargin>
        <Heading>{children}</Heading>
      </HomeContainer>
    </Wrapper>
  )
}

export default HomePageHeading
